import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import {
  FaLinkedin,
  FaTwitterSquare,
  FaEnvelopeSquare,
  FaPhoneSquareAlt,
} from "react-icons/fa";

import LogoSVG from "../images/logo.svg";

const Footer = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query FooterItemsQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "menu-item" } } }
      ) {
        edges {
          node {
            frontmatter {
              page
              dropdownItems {
                page
              }
            }
          }
        }
      }
    }
  `);

  const menu = allMarkdownRemark.edges.map(({ node }) => node.frontmatter);
  const visiblePages = menu.reduce(
    (acc, cur) =>
      cur.dropdownItems === null
        ? [...acc, cur.page]
        : [...acc, cur.page, ...cur.dropdownItems.map(({ page }) => page)],
    []
  );

  return (
    <div className="w-full p-8 md:p-12 xl:p-24 3xl:px-32 bg-primary text-white">
      <div className="w-full grid grid-cols-2 lg:grid-cols-4 gap-x-4 lg:gap-x-12 gap-y-8 mb-8">
        <div className="col-span-2 lg:col-span-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 sm:justify-items-center lg:justify-items-start gap-4">
          <div className="flex items-center select-none">
            <div
              className="h-8 w-8 md:h-12 md:w-12 square-logo-div"
              style={{ backgroundImage: `url(${LogoSVG})` }}
            />
            <div className="text-base md:text-lg lg:text-xl ml-2 text-secondary">
              INTEGRUM ESG
            </div>
          </div>
          <div className="pl-10 sm:pl-0 lg:pl-14 text-base font-thin text-left sm:text-center lg:text-left">
            Have a question? We're here to help.
            <br />
            Call us on{" "}
            <a
              href="tel:02033271555"
              aria-label="Call Integrum ESG"
              className="underline"
            >
              020 3327 1555
            </a>
            .
          </div>
        </div>
        <div className="col-span-2 sm:col-span-1 text-left sm:text-center lg:text-left text-lg">
          <div className="mb-4">ABOUT US</div>
          <ul className="list-disc list-inside">
            {visiblePages.find((p) => p === "/our-story") !== undefined && (
              <li>
                <Link
                  className="underline lg:no-underline hover:underline"
                  to="/our-story"
                >
                  Our Story
                </Link>
              </li>
            )}
            {visiblePages.find((p) => p === "/how-it-works") !== undefined && (
              <li>
                <Link
                  className="underline lg:no-underline hover:underline"
                  to="/how-it-works"
                >
                  How it works
                </Link>
              </li>
            )}
            {visiblePages.find((p) => p === "/meet-the-team") !== undefined && (
              <li>
                <Link
                  className="underline lg:no-underline hover:underline"
                  to="/meet-the-team"
                >
                  The Team
                </Link>
              </li>
            )}
            {visiblePages.find((p) => p === "/metrics") !== undefined && (
              <li>
                <Link
                  className="underline lg:no-underline hover:underline"
                  to="/metrics"
                >
                  Metrics
                </Link>
              </li>
            )}
            {visiblePages.find((p) => p === "/insights") !== undefined && (
              <li>
                <Link
                  className="underline lg:no-underline hover:underline"
                  to="/insights"
                >
                  Insights
                </Link>
              </li>
            )}

            {visiblePages.find((p) => p === "/careers") !== undefined && (
              <li>
                <Link
                  className="underline lg:no-underline hover:underline"
                  to="/careers"
                >
                  Careers
                </Link>
              </li>
            )}
          </ul>
        </div>
        <div className="col-span-2 sm:col-span-1 text-left sm:text-center lg:text-left text-lg">
          <div className="mb-4">THE PRODUCT</div>
          <ul className="list-disc list-inside">
            {visiblePages.find((p) => p === "/esg-dashboard") !== undefined && (
              <li>
                <Link
                  className="underline lg:no-underline hover:underline"
                  to="/esg-dashboard"
                >
                  ESG for Public Markets
                </Link>
              </li>
            )}
            {visiblePages.find((p) => p === "/private-equity") !==
              undefined && (
              <li>
                <Link
                  className="underline lg:no-underline hover:underline"
                  to="/private-equity"
                >
                  ESG for Private Markets
                </Link>
              </li>
            )}

            {visiblePages.find((p) => p === "/tutorial-video-hub") !==
              undefined && (
              <li>
                <Link
                  className="underline lg:no-underline hover:underline"
                  to="/tutorial-video-hub"
                >
                  Tutorials
                </Link>
              </li>
            )}
          </ul>
        </div>
        <div className="col-span-2 sm:col-span-1 text-left sm:text-center lg:text-left text-lg">
          <div className="mb-4">INFO</div>
          <ul className="list-disc list-inside">
            <li>
              <Link
                className="underline lg:no-underline hover:underline"
                to="/contact-us"
              >
                Contact Us
              </Link>
            </li>
            <li>
              <Link
                className="underline lg:no-underline hover:underline"
                to="/careers"
              >
                Careers
              </Link>
            </li>
            <li>
              <Link
                className="underline lg:no-underline hover:underline"
                to="/policies"
              >
                Policies
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full flex flex-row justify-between items-start md:items-end">
        <div className="flex flex-col justify-end mr-4">
          <div className="text-sm">
            © 2023 INTEGRUM ESG. All rights reserved. Integrum and Geosphere are
            trading names of Integrum ESG Limited.
          </div>
          <div className="text-sm">
            <a href="https://s3.eu-west-2.amazonaws.com/integrumesg.com-policy-pdfs/Template+Malk+x+Integrum+ESG+Platform+T%26Cs+FINAL.pdf" className="underline">
              Subscription Terms & Conditions
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row text-5xl">
          <div className="flex flex-row mb-4 md:mb-0 md:mr-4">
            <a
              href="https://www.linkedin.com/company/integrumesg/"
              aria-label="Integrum ESG LinkedIn link"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin />
            </a>
          </div>
          <div className="flex flex-row">
            <a
              href="mailto:contact@integrumesg.com"
              aria-label="Email Integrum ESG"
              className="mr-4"
            >
              <FaEnvelopeSquare />
            </a>
            <a href="tel:02033271555" aria-label="Call Integrum ESG">
              <FaPhoneSquareAlt />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
